/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/**
 * Margins and paddings
 */
/**
 * Mobile breakpoints:
 * These indicate the minimum width for each breakpoint.
 * If you intend to change the breakpoints, you should
 * change the minimum/maximum widths assigned to the
 * breakpoints in pure/css/grids-responsive.css too.
 * Approx 16px per em.
 */
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/**
 * Sizes
 */
/**
 * Colors
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  FLOATING ACTION BUTTONS
  Used for main menu
-------------------------------*/
/* ---------- FAB MAIN BUTTON ICON ---------- */
/* Use icon_menu_rocket_black.png if black icons are preferred */
/* ---------- FAB COLORS ---------- */
/* ---------- FAB SIZE ---------- */
/* ---------- FAB MARGINS / PADDINGS ---------- */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  LEFT SIDEBAR
-------------------------------*/
/* ---------- BACKGROUND ---------- */
/* ---------- TEXT COLORS ---------- */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Borders
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Typography
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/**
 * Sizes
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/**
 * Colors (Buttons)
 */
/*------------------------------------*\
    # PROJECT PAGE
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  FLOATING ACTION BUTTONS
  Used for main menu
-------------------------------*/
/* ---------- FAB MAIN BUTTON ICON ---------- */
/* Use icon_menu_rocket_black.png if black icons are preferred */
/* ---------- FAB COLORS ---------- */
/* ---------- FAB SIZE ---------- */
/* ---------- FAB MARGINS / PADDINGS ---------- */
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARD SLIDER
\*------------------------------------*/
/**
 * Background
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # PROJECT TITLE CARD
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARDS
\*------------------------------------*/
/**
 * Sizes (CTA Buttons)
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/**
 * Margins and paddings
 */
/**
 * Mobile breakpoints:
 * These indicate the minimum width for each breakpoint.
 * If you intend to change the breakpoints, you should
 * change the minimum/maximum widths assigned to the
 * breakpoints in pure/css/grids-responsive.css too.
 * Approx 16px per em.
 */
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/**
 * Sizes
 */
/**
 * Colors
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  FLOATING ACTION BUTTONS
  Used for main menu
-------------------------------*/
/* ---------- FAB MAIN BUTTON ICON ---------- */
/* Use icon_menu_rocket_black.png if black icons are preferred */
/* ---------- FAB COLORS ---------- */
/* ---------- FAB SIZE ---------- */
/* ---------- FAB MARGINS / PADDINGS ---------- */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  LEFT SIDEBAR
-------------------------------*/
/* ---------- BACKGROUND ---------- */
/* ---------- TEXT COLORS ---------- */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Borders
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Typography
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/**
 * Sizes
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/**
 * Colors (Buttons)
 */
/*------------------------------------*\
    # PROJECT PAGE
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  FLOATING ACTION BUTTONS
  Used for main menu
-------------------------------*/
/* ---------- FAB MAIN BUTTON ICON ---------- */
/* Use icon_menu_rocket_black.png if black icons are preferred */
/* ---------- FAB COLORS ---------- */
/* ---------- FAB SIZE ---------- */
/* ---------- FAB MARGINS / PADDINGS ---------- */
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARD SLIDER
\*------------------------------------*/
/**
 * Background
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # PROJECT TITLE CARD
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARDS
\*------------------------------------*/
/**
 * Sizes (CTA Buttons)
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # MARGIN & PADDING
\*------------------------------------*/
/*------------------------------------*\
    # BORDERS
\*------------------------------------*/
/*------------------------------------*\
    # TEXT
\*------------------------------------*/
/*------------------------------------*\
    # CURSOR TYPES
\*------------------------------------*/
.button.disabled.cursor-not-allowed, .button.disabled.cursor-not-allowed:hover, a.button.disabled.cursor-not-allowed, a.button.disabled.cursor-not-allowed:hover {
  cursor: not-allowed !important;
}

/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
.nav-buttons .button:hover:not(.active), .nav-buttons a.button:hover:not(.active), .button.icon:hover, a.button.icon:hover, .button:hover, a.button:hover, .dropdown-button-group div[class*=dropdown---dropup] button:hover, .dropdown-button-group button:hover {
  background-color: #a6a6a6;
  color: #fff;
}

/* to be paired with react-dropdown */
/* https://github.com/trendmicro-frontend/react-dropdown */
.dropdown-button-group div[class*=dropdown---dropup] button, .dropdown-button-group button {
  display: block;
  position: relative;
  font-size: 1em;
  line-height: 1;
  font-weight: 600;
  color: #fff;
  text-align: center;
  padding: 12px 18px;
  border-radius: 20px;
  background-color: #ececec;
  background-image: none;
  cursor: pointer;
  border: none;
  transition: all 0s ease 0s;
  /* extending .inline */
  display: inline;
  /* extending .icon */
  width: 40px;
  height: 40px;
  padding: 0px;
  background-color: #ececec;
}
@media screen and (max-width: 47.99em) {
  .dropdown-button-group div[class*=dropdown---dropup] button:hover, .dropdown-button-group button:hover {
    background-color: #ececec;
  }
}
.dropdown-button-group div[class*=dropdown---dropup] button[aria-expanded=true], .dropdown-button-group button[aria-expanded=true] {
  background-color: #bbb !important;
}
.dropdown-button-group div[class*=dropdown---dropup] button .more-icon, .dropdown-button-group button .more-icon {
  width: 16px;
}

.dropdown-button-group div[class*=dropdown---dropup] div[role=menu], .dropdown-button-group div[role=menu] {
  border: 1px solid transparent;
  z-index: 5; /* lower than profile drawer and topbar */
}
.dropdown-button-group div[class*=dropdown---dropup] div[role=menu]::before, .dropdown-button-group div[role=menu]::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid white;
  position: absolute;
  left: 20px;
  top: -4px;
}
.dropdown-button-group div[class*=dropdown---pull-right][role=menu]::before {
  left: auto;
  right: 20px;
}
.dropdown-button-group div[class*=dropdown---dropup] div[role=menu] a, .dropdown-button-group div[role=menu] a, .dropdown-button-group div[class*=dropdown---dropup] div[role=menu] .link, .dropdown-button-group div[role=menu] .link {
  display: block;
  padding: 4px 16px;
  clear: both;
  font-weight: normal;
  line-height: 20px;
  color: #222;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
}
.dropdown-button-group div[role=menu] a:hover, .dropdown-button-group div[role=menu] .link:hover {
  text-decoration: none;
  color: #222;
  background-color: #eee;
}

/*------------------------------------*\
    # IFRAMES
\*------------------------------------*/
a.button:hover {
  text-decoration: none;
}

.button, a.button {
  box-sizing: border-box;
  display: block;
  position: relative;
  font-size: 1em;
  line-height: 1.375;
  font-weight: 600;
  color: #fff;
  text-align: center;
  padding: 12px 18px;
  border-radius: 20px;
  background-color: #666;
  cursor: pointer;
  border: none;
  margin-bottom: 10px;
  /* ----------------------------------------------------
    BUTTON STATES
  ---------------------------------------------------- */
  /* ----------------------------------------------------
    SOCIAL LOGIN BUTTONS
  ---------------------------------------------------- */
  /* ----------------------------------------------------
    CHALLENGE QUIZ BUTTONS
  ---------------------------------------------------- */
  /* ----------------------------------------------------
    DATE INPUT BUTTONS
  ---------------------------------------------------- */
}
.button:focus, a.button:focus {
  outline: none;
}
.button.inline, a.button.inline {
  display: inline;
  vertical-align: bottom;
}
.button.inline-block, a.button.inline-block {
  display: inline-block;
  vertical-align: bottom;
}
.button.fullwidth, a.button.fullwidth {
  width: 100%;
}
.button.long, a.button.long {
  width: 100%;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.button.medium, a.button.medium {
  width: 100%;
  max-width: 140px;
}
.button.large-padding-vertical, a.button.large-padding-vertical {
  padding: 18px 18px;
}
.button.small, a.button.small {
  padding: 8px 10px;
  font-weight: normal;
  border-radius: 15px;
}
.button.clear, .button.icon.clear, a.button.clear, a.button.icon.clear {
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  color: #292929;
}
.button img, a.button img {
  margin: 6px auto 12px auto;
}
.button.icon, a.button.icon {
  width: 40px;
  height: 40px;
  margin-left: 5px;
  padding: 0px;
  border-radius: 100%;
  background-color: #ececec;
}
.button.icon .fas, a.button.icon .fas {
  margin-top: 10px;
}
.button.icon.inline-block span, a.button.icon.inline-block span {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.button.liked, .button.liked:hover, a.button.liked, a.button.liked:hover {
  background-color: #e4174b;
}
.button.bookmarked, .button.bookmarked:hover, a.button.bookmarked, a.button.bookmarked:hover {
  background-color: #ecd32b;
}
.button.active, a.button.active {
  background-color: #555555;
  border-color: #555555;
}
.button.inactive, a.button.inactive {
  background-color: #afafaf;
  border-color: #afafaf;
}
.button.toggle, a.button.toggle {
  color: #292929;
}
.button.toggle.active, .button.toggle.inactive, a.button.toggle.active, a.button.toggle.inactive {
  background-color: transparent;
  border-color: transparent;
}
.button.toggle.active, a.button.toggle.active {
  color: #bc1954;
}
.button.toggle span, a.button.toggle span {
  display: inline-block;
  padding: 5px;
  border-bottom: 2px solid transparent;
}
.button.toggle.active span, a.button.toggle.active span {
  border-bottom: 2px solid #bc1954;
}
.button.toggle.inactive:hover span, a.button.toggle.inactive:hover span {
  color: #981444;
}
.button.disabled, .button.disabled:hover, a.button.disabled, a.button.disabled:hover {
  cursor: default;
  background-color: #bbbbbb;
  opacity: 0.5;
}
.button.cta, .button.cta a, a.button.cta, a.button.cta a {
  background-color: #bc1954;
  color: #fff;
}
.button.cta:hover, .button.cta a:hover, a.button.cta:hover, a.button.cta a:hover {
  background-color: #981444;
}
.button.cta.link, a.button.cta.link {
  color: #fff;
}
.button.cta-inverted, .button.cta-inverted a, a.button.cta-inverted, a.button.cta-inverted a {
  background-color: #fff;
  color: #bc1954;
}
.button.cta-inverted:hover, .button.cta-inverted a:hover, a.button.cta-inverted:hover, a.button.cta-inverted a:hover {
  background-color: #ebebeb;
}
.button.danger, a.button.danger {
  background-color: #B71C1C;
}
.button.danger:hover, a.button.danger:hover {
  background-color: #941717;
}
.button.info, .button.info a, a.button.info, a.button.info a {
  background-color: #2e8ad7;
}
.button.info:hover, .button.info a:hover, a.button.info:hover, a.button.info a:hover {
  background-color: #2375b9;
}
.button.floating, a.button.floating {
  background-color: #bc1954;
  border: 1px solid white;
}
.button.floating:hover, a.button.floating:hover {
  background-color: #bc1954;
}
.button.button-fb, a.button.button-fb {
  background: #4c6dcb;
  border-radius: 100%;
}
.button.button-fb i, a.button.button-fb i {
  font-size: 25px;
  margin-left: -6px;
  margin-top: -3px;
}
.button.button-fb:hover, a.button.button-fb:hover {
  background: #3855a7;
}
.button.button-google, a.button.button-google {
  background: #4285F4;
}
.button.button-google:hover, a.button.button-google:hover {
  background: #76A7FA;
}
.button.button-microsoft, a.button.button-microsoft {
  background: #2f2f2f;
  border-radius: 50%;
}
.button.button-microsoft:hover, a.button.button-microsoft:hover {
  background: #3f3f3f;
}
.button.button-microsoft .button-microsoft-icon, a.button.button-microsoft .button-microsoft-icon {
  position: absolute;
  left: 9px;
  top: 9px;
}
.button.button-microsoft .button-microsoft-icon:before, a.button.button-microsoft .button-microsoft-icon:before {
  content: url("../../../images/core/microsoft.png");
}
.button.button-fb, .button.button-google, .button.button-microsoft, a.button.button-fb, a.button.button-google, a.button.button-microsoft {
  width: 38px;
  height: 38px;
}
.button.button-login, a.button.button-login {
  padding: 6px 18px;
  font-size: 0.75em;
  vertical-align: middle;
  background: #bb1853;
}
.button.quizinput, a.button.quizinput {
  background-color: #eee;
  border: 1px solid #ccc;
  color: inherit;
  font-weight: normal;
  border-radius: 10px;
  max-width: 85vw;
}
@media screen and (min-width: 48em) {
  .button.quizinput, a.button.quizinput {
    max-width: 60vw;
  }
}
.button.quizinput.selected, a.button.quizinput.selected {
  background-color: #ccc;
}
.button.quizinput.pseudo-readonly, .button.quizinput.pseudo-readonly:hover, a.button.quizinput.pseudo-readonly, a.button.quizinput.pseudo-readonly:hover {
  opacity: 1;
  background-color: #eee;
  color: #777;
  border: 1px solid #ccc;
}
.button.gridquizinput, a.button.gridquizinput {
  background-color: #fff;
  color: #000;
  font-weight: normal;
  border-radius: 0;
  position: relative;
  padding: 0;
}
.button.gridquizinput.pseudo-readonly, .button.gridquizinput.pseudo-readonly:hover, a.button.gridquizinput.pseudo-readonly, a.button.gridquizinput.pseudo-readonly:hover {
  opacity: 1;
  background-color: #eee;
}
.button.gridquizinput .gridquizinput-selected, a.button.gridquizinput .gridquizinput-selected {
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  border: 4px solid #000;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: none;
}
.button.gridquizinput .gridquizinput-selected.correct, a.button.gridquizinput .gridquizinput-selected.correct {
  border: 4px solid #85d02f;
}
.button.gridquizinput.selected .gridquizinput-selected, a.button.gridquizinput.selected .gridquizinput-selected {
  display: block;
}
.button.gridquizinput img, a.button.gridquizinput img {
  margin: 0;
}
.button.dateinput, a.button.dateinput {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 0.5em 0.6em;
  border-radius: 8px;
  box-sizing: border-box;
  margin-bottom: 0.6em;
  color: #757575;
  font-weight: normal;
  text-align: left;
}
.button.dateinput.disabled, .button.dateinput.disabled:hover, a.button.dateinput.disabled, a.button.dateinput.disabled:hover {
  background-color: #eee;
  color: #777;
  border: 1px solid #ccc;
  opacity: 1;
}

/* ----------------------------------------------------
  METADATA BUTTONS
---------------------------------------------------- */
.button-metadata {
  background: #ccc;
  color: #111;
  font-size: 0.875em;
  padding: 3px 10px;
  border-radius: 8px;
}
.button-metadata:hover {
  background: #b3b3b3;
}
.button-metadata.dark {
  background: #555;
  color: #fff;
}
.button-metadata.dark:hover {
  background: #3c3c3c;
}

/* ----------------------------------------------------
  NAVIGATIONAL TAB BUTTONS
---------------------------------------------------- */
.nav-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-buttons .button, .nav-buttons a.button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-left: 20px;
  /* reset padding */
  padding: 8px 0;
}
.nav-buttons .button .fas, .nav-buttons a.button .fas {
  margin-right: 10px;
}
.nav-buttons .button span, .nav-buttons a.button span {
  display: block;
  padding: 7px 18px;
}
.nav-buttons .button.active, .nav-buttons a.button.active {
  color: #fff;
  background-color: #292929;
}
.nav-buttons .button.inactive, .nav-buttons a.button.inactive {
  color: #000;
  background-color: #eeeeee;
}
.nav-buttons .button:hover, .nav-buttons a.button:hover {
  /* Do not show hover effect for currently highlighted tab */
}
@media screen and (max-width: 48em) {
  .nav-buttons {
    font-size: 0.75em;
  }
  .nav-buttons .button {
    margin-left: 10px;
  }
  .nav-buttons .button span {
    display: inline;
    padding: 5px 15px;
  }
  .nav-buttons :first-child.button {
    margin-left: 0;
  }
}

/* ----------------------------------------------------
  BACK BUTTON FOR SCREENS WITHOUT TOPBARS
---------------------------------------------------- */
.topbarless-back-button {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 36px;
  background: #000;
  border-radius: 50%;
  opacity: 1;
}
.topbarless-back-button:hover {
  opacity: 0.75;
}
.topbarless-back-button i {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  color: #fff;
}
@media screen and (min-width: 35.5em) {
  .topbarless-back-button {
    width: 48px;
    height: 48px;
  }
  .topbarless-back-button i {
    font-size: 2em;
  }
}

/* ----------------------------------------------------
  DROPDOWN BUTTON
---------------------------------------------------- */
/* to be paired with react-dropdown */
/* https://github.com/trendmicro-frontend/react-dropdown */
.dropdown-button-group {
  position: relative;
  display: inline-block;
  margin-left: 5px;
  margin-bottom: 10px;
}
.dropdown-button-group.sidebar-reset-topic, .dropdown-button-group.main-topic-button-dropdown {
  margin-bottom: 0;
}
.dropdown-button-group div[class*=dropdown---dropup] div[role=menu]::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: none;
  border-top: 5px solid white;
  position: absolute;
  left: 20px;
  top: auto;
  bottom: -5px;
}
.dropdown-button-group div[class*=dropdown---dropup] div[role=menu][class*=dropdown---pull-right]::before {
  left: auto;
  right: 20px;
}

/* ----------------------------------------------------
  MISCELLANEOUS
---------------------------------------------------- */
/* Notification Dots */
.notification-icon-wrap {
  position: relative;
}
.notification-icon-wrap .notification-dot {
  background: red;
  display: inline-block;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 0;
  right: 0;
}

.pointer {
  cursor: pointer;
}
