/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/**
 * Margins and paddings
 */
/**
 * Mobile breakpoints:
 * These indicate the minimum width for each breakpoint.
 * If you intend to change the breakpoints, you should
 * change the minimum/maximum widths assigned to the
 * breakpoints in pure/css/grids-responsive.css too.
 * Approx 16px per em.
 */
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/**
 * Sizes
 */
/**
 * Colors
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  FLOATING ACTION BUTTONS
  Used for main menu
-------------------------------*/
/* ---------- FAB MAIN BUTTON ICON ---------- */
/* Use icon_menu_rocket_black.png if black icons are preferred */
/* ---------- FAB COLORS ---------- */
/* ---------- FAB SIZE ---------- */
/* ---------- FAB MARGINS / PADDINGS ---------- */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  LEFT SIDEBAR
-------------------------------*/
/* ---------- BACKGROUND ---------- */
/* ---------- TEXT COLORS ---------- */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Borders
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Typography
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/**
 * Sizes
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/**
 * Colors (Buttons)
 */
/*------------------------------------*\
    # PROJECT PAGE
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  FLOATING ACTION BUTTONS
  Used for main menu
-------------------------------*/
/* ---------- FAB MAIN BUTTON ICON ---------- */
/* Use icon_menu_rocket_black.png if black icons are preferred */
/* ---------- FAB COLORS ---------- */
/* ---------- FAB SIZE ---------- */
/* ---------- FAB MARGINS / PADDINGS ---------- */
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARD SLIDER
\*------------------------------------*/
/**
 * Background
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # PROJECT TITLE CARD
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARDS
\*------------------------------------*/
/**
 * Sizes (CTA Buttons)
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/**
 * Margins and paddings
 */
/**
 * Mobile breakpoints:
 * These indicate the minimum width for each breakpoint.
 * If you intend to change the breakpoints, you should
 * change the minimum/maximum widths assigned to the
 * breakpoints in pure/css/grids-responsive.css too.
 * Approx 16px per em.
 */
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/**
 * Sizes
 */
/**
 * Colors
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  FLOATING ACTION BUTTONS
  Used for main menu
-------------------------------*/
/* ---------- FAB MAIN BUTTON ICON ---------- */
/* Use icon_menu_rocket_black.png if black icons are preferred */
/* ---------- FAB COLORS ---------- */
/* ---------- FAB SIZE ---------- */
/* ---------- FAB MARGINS / PADDINGS ---------- */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  LEFT SIDEBAR
-------------------------------*/
/* ---------- BACKGROUND ---------- */
/* ---------- TEXT COLORS ---------- */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Borders
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Typography
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/**
 * Sizes
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/**
 * Colors (Buttons)
 */
/*------------------------------------*\
    # PROJECT PAGE
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  FLOATING ACTION BUTTONS
  Used for main menu
-------------------------------*/
/* ---------- FAB MAIN BUTTON ICON ---------- */
/* Use icon_menu_rocket_black.png if black icons are preferred */
/* ---------- FAB COLORS ---------- */
/* ---------- FAB SIZE ---------- */
/* ---------- FAB MARGINS / PADDINGS ---------- */
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARD SLIDER
\*------------------------------------*/
/**
 * Background
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # PROJECT TITLE CARD
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARDS
\*------------------------------------*/
/**
 * Sizes (CTA Buttons)
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # MARGIN & PADDING
\*------------------------------------*/
/*------------------------------------*\
    # BORDERS
\*------------------------------------*/
/*------------------------------------*\
    # TEXT
\*------------------------------------*/
.iframe-media-controls {
  font-size: 1.25em;
}

/*------------------------------------*\
    # CURSOR TYPES
\*------------------------------------*/
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/* to be paired with react-dropdown */
/* https://github.com/trendmicro-frontend/react-dropdown */
/*------------------------------------*\
    # IFRAMES
\*------------------------------------*/
.iframe-scroll-wrapper {
  /* For making iframes scrollable on iOS / Safari */
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

/* ----------------------------------------------------
  SECTION TITLE
---------------------------------------------------- */
/* ---------- IFRAMES WITHIN PAGES ---------- */
.iframe-scroll-wrapper {
  /* For making iframes scrollable on iOS / Safari */
}

.iframe-container {
  position: relative;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  z-index: 1;
}
.iframe-container.embed-vimeo {
  padding-bottom: 80%;
}
@media screen and (max-width: 47.99em) {
  .iframe-container.embed-vimeo {
    padding-bottom: 65%;
  }
}
@media screen and (max-width: 47.99em) {
  .iframe-container.adobe-pdf-viewer {
    min-height: 200px;
  }
}

@media screen and (max-width: 48em) {
  .iframe-container-long {
    padding-bottom: 150%;
  }
}
.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

/* ---------- CONTROLS FOR IFRAME WITHIN PAGES ---------- */
/*
  Usage example:

  <div className="iframe-media-controls">
    <a href="#">
      <span class="fas fa-expand-arrows-alt"></span> // Expand icon
    </a>
  </div>
  <div className="iframe-container">
    [YOUR IFRAME]
  </div>
*/
.iframe-media-controls {
  background: #555;
  color: #fff;
  border-radius: 15px 15px 0 0;
  padding: 5px 15px;
  text-align: right;
}
.iframe-media-controls a, .iframe-media-controls a:link, .iframe-media-controls a:visited {
  color: #fff;
}
.iframe-media-controls button {
  background: transparent;
  border: none;
}

/* ---------- FULL SCREEN IFRAMES ---------- */
.fullscreen-frame-wrap {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  border: 0;
  margin: 0;
  padding: 0;
  line-height: 1;
}

.topbar-wrap + .page-container .fullscreen-frame-wrap {
  top: 50px;
}

.fullscreen-frame {
  width: 100%;
  height: 100%;
  border: 0;
}
