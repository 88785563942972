/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/

/* ---------- COLOR DEFINITIONS ---------- */

$gametize-pink: #bc1954;
$gametize-pink-light: #FCC1D6;
$gametize-green: #85d02f;

$gametize-red: #B71C1C;

$gametize-blue: #2e8ad7;

/* ---------- GREYSCALE ---------- */

$white: #fff;
$gray-lighter: #f6f6f6;
$gray-light: #eee;
$gray-medium: #ccc;
$gray: #666;
$gray-dark: #555;
$gray-darker: #292929;
$gray-darkest: #111;
$black: #000;

/* ---------- INTERFACE ELEMENTS ---------- */

/* Backgrounds */
$main-background: $white;
$dark-background: $gray-darkest;

/* Text */
$main-text: $gray-darker;
$fade-text: $gray;
$light-text: $gray-lighter;
$smalltext-color: $fade-text;

/* Warnings */
$danger: $gametize-red;

/* Call to Action */
$cta: $gametize-pink;
$cta-text: $white;

/* Segment Dividers */
$divider-background: $gray-light;

/* Highlighted / Hover / Selected State */
$highlight-background: $gametize-pink;
$highlight-text: $white;

/* Links */
$link-color: $gametize-pink;

$field-border-color: #129FEA; // copied from pure-css
