/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/**
 * Margins and paddings
 */
/**
 * Mobile breakpoints:
 * These indicate the minimum width for each breakpoint.
 * If you intend to change the breakpoints, you should
 * change the minimum/maximum widths assigned to the
 * breakpoints in pure/css/grids-responsive.css too.
 * Approx 16px per em.
 */
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/**
 * Sizes
 */
/**
 * Colors
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  FLOATING ACTION BUTTONS
  Used for main menu
-------------------------------*/
/* ---------- FAB MAIN BUTTON ICON ---------- */
/* Use icon_menu_rocket_black.png if black icons are preferred */
/* ---------- FAB COLORS ---------- */
/* ---------- FAB SIZE ---------- */
/* ---------- FAB MARGINS / PADDINGS ---------- */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  LEFT SIDEBAR
-------------------------------*/
/* ---------- BACKGROUND ---------- */
/* ---------- TEXT COLORS ---------- */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Borders
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Typography
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/**
 * Sizes
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/**
 * Colors (Buttons)
 */
/*------------------------------------*\
    # PROJECT PAGE
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  FLOATING ACTION BUTTONS
  Used for main menu
-------------------------------*/
/* ---------- FAB MAIN BUTTON ICON ---------- */
/* Use icon_menu_rocket_black.png if black icons are preferred */
/* ---------- FAB COLORS ---------- */
/* ---------- FAB SIZE ---------- */
/* ---------- FAB MARGINS / PADDINGS ---------- */
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARD SLIDER
\*------------------------------------*/
/**
 * Background
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # PROJECT TITLE CARD
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARDS
\*------------------------------------*/
/**
 * Sizes (CTA Buttons)
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/**
 * Margins and paddings
 */
/**
 * Mobile breakpoints:
 * These indicate the minimum width for each breakpoint.
 * If you intend to change the breakpoints, you should
 * change the minimum/maximum widths assigned to the
 * breakpoints in pure/css/grids-responsive.css too.
 * Approx 16px per em.
 */
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/**
 * Sizes
 */
/**
 * Colors
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  FLOATING ACTION BUTTONS
  Used for main menu
-------------------------------*/
/* ---------- FAB MAIN BUTTON ICON ---------- */
/* Use icon_menu_rocket_black.png if black icons are preferred */
/* ---------- FAB COLORS ---------- */
/* ---------- FAB SIZE ---------- */
/* ---------- FAB MARGINS / PADDINGS ---------- */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  LEFT SIDEBAR
-------------------------------*/
/* ---------- BACKGROUND ---------- */
/* ---------- TEXT COLORS ---------- */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Borders
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Typography
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/**
 * Sizes
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/**
 * Colors (Buttons)
 */
/*------------------------------------*\
    # PROJECT PAGE
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  FLOATING ACTION BUTTONS
  Used for main menu
-------------------------------*/
/* ---------- FAB MAIN BUTTON ICON ---------- */
/* Use icon_menu_rocket_black.png if black icons are preferred */
/* ---------- FAB COLORS ---------- */
/* ---------- FAB SIZE ---------- */
/* ---------- FAB MARGINS / PADDINGS ---------- */
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARD SLIDER
\*------------------------------------*/
/**
 * Background
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # PROJECT TITLE CARD
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARDS
\*------------------------------------*/
/**
 * Sizes (CTA Buttons)
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # MARGIN & PADDING
\*------------------------------------*/
/*------------------------------------*\
    # BORDERS
\*------------------------------------*/
/*------------------------------------*\
    # TEXT
\*------------------------------------*/
/*------------------------------------*\
    # CURSOR TYPES
\*------------------------------------*/
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
.discussions-components .discussions-post-actions .button-with-text:hover {
  background-color: #a6a6a6;
  color: #fff;
}

/* to be paired with react-dropdown */
/* https://github.com/trendmicro-frontend/react-dropdown */
/*------------------------------------*\
    # IFRAMES
\*------------------------------------*/
/* ---------- ALL SHARED DISCUSSIONS COMPONENTS ---------- */
.discussions-components .discussions-embeds-container {
  margin-top: 5px;
}
@media screen and (max-width: 47.99em) {
  .discussions-components .discussions-embeds-container {
    max-width: none;
  }
}
.discussions-components .discussions-embeds-container .embed-item {
  width: calc(33% - 40px);
  margin: 10px;
  display: inline-block;
}
@media screen and (max-width: 47.99em) {
  .discussions-components .discussions-embeds-container .embed-item {
    width: calc(50% - 10px);
    margin: 5px;
  }
  .discussions-components .discussions-embeds-container .embed-item.embed-item-1, .discussions-components .discussions-embeds-container .embed-item.embed-item-3 {
    margin-left: 0;
  }
}
.discussions-components .discussions-embeds-container .embed-item.embed-item-1 {
  margin-left: 0;
}
.discussions-components .discussions-embeds-container .embed-item img {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  object-fit: cover;
  cursor: pointer;
}
.discussions-components .discussions-post-username {
  font-weight: bold;
}
.discussions-components .discussions-post-date {
  color: #666;
}
.discussions-components .discussions-post-actions {
  box-sizing: border-box;
  padding-right: 0;
  text-align: right;
}
.discussions-components .discussions-post-actions .button {
  margin-bottom: 0;
}
.discussions-components .discussions-post-actions .like-icon {
  background-image: url("../../images/core/icon_like.png");
  height: 18px;
  margin-top: 1px; /* offset since the icon is 18px instead of 20px */
}
.discussions-components .discussions-post-actions .comment-icon {
  background-image: url("../../images/core/icon_comment.png");
  width: 18px;
  height: 18px;
  margin-top: 1px; /* offset since the icon is 18px instead of 20px */
}
.discussions-components .discussions-post-actions .button-with-text {
  background-color: #ececec;
  border-radius: 25px;
  /* TODO: Port to library for scalability */
}
.discussions-components .discussions-post-actions .button-with-text .button-text {
  color: #111;
  margin-left: 30px;
  margin-right: 10px;
}
.discussions-components .discussions-post-actions .button-with-text .button-text.like-count {
  color: #bc1954;
}
.discussions-components .discussions-post-actions .button-with-text .comment-icon {
  margin-left: 1px; /* offset since the icon is 18px instead of 20px */
}
.discussions-components .discussions-post-actions .button-with-text .like-icon, .discussions-components .discussions-post-actions .button-with-text .comment-icon {
  position: absolute;
  left: 10px;
  margin-left: 5px;
  margin-top: 2px;
}
.discussions-components .discussions-post-actions .button-with-text.liked {
  background-color: #bc1954;
}
.discussions-components .discussions-post-actions .button-with-text.liked .button-text {
  color: #fff;
}
.discussions-components .discussions-post-actions .button-with-text.liked .like-icon {
  background-position: -20px 0;
}
.discussions-components .discussions-post-image-upload {
  margin-top: 10px;
  margin-bottom: 5px;
}
.discussions-components .discussions-post-image-upload .image-input-label-container {
  margin-bottom: 0;
}
.discussions-components .discussions-post-image-upload .image-input-label-container .image-input-label {
  margin-top: 0;
}
@media screen and (min-width: 48em) and (max-width: 79.99em) {
  .discussions-components .discussions-post-image-upload .image-input-label-container .image-input-label {
    width: 8em;
    padding-top: 8em;
  }
  .discussions-components .discussions-post-image-upload .image-input-label-container .image-input-label .image-input-label-text {
    top: 8.5em;
  }
  .discussions-components .discussions-post-image-upload .image-input-label-container .image-input-label .fas {
    font-size: 5em;
  }
}
@media screen and (min-width: 48em) {
  .discussions-components .discussions-post-image-upload .image-input-label-container {
    text-align: left;
  }
}
.discussions-components .discussions-post-submit {
  width: 50%;
  margin: auto;
}
.discussions-components .discussions-post-submit button {
  width: 100%;
}
@media screen and (max-width: 47.99em) {
  .discussions-components .discussions-post-submit {
    width: 100%;
  }
}

/* ---------- MAIN DISCUSSIONS PAGE ---------- */
.discussions-main-container {
  /*
    Discussions Header:
    Encompasses boards + filter
  */
}
@media screen and (min-width: 80em) {
  .discussions-main-container .innerblock {
    max-width: 80em;
  }
}
.discussions-main-container .discussions-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.discussions-main-container .discussions-header .discussions-board-chips {
  display: flex;
  margin-right: 10px;
  max-width: 52.5vw;
  overflow-x: auto;
  scrollbar-width: thin;
}
@media screen and (max-width: 63.99em) {
  .discussions-main-container .discussions-header .discussions-board-chips {
    scrollbar-width: none;
    max-width: 75vw;
  }
}
@media screen and (max-width: 35.49em) {
  .discussions-main-container .discussions-header .discussions-board-chips {
    max-width: 60vw;
  }
}
.discussions-main-container .discussions-header .discussions-board-chips .discussions-board-chip {
  margin-right: 5px;
  padding: 10px 20px;
  font-size: 0.9em;
  border-radius: 20px;
  background-color: #d9d9d9;
}
@media screen and (max-width: 47.99em) {
  .discussions-main-container .discussions-header .discussions-board-chips .discussions-board-chip {
    font-size: 0.7em;
    padding: 5px 10px;
    margin-bottom: 2px;
  }
}
.discussions-main-container .discussions-header .discussions-board-chips .discussions-board-chip.inactive {
  color: black;
  max-width: 400px;
}
.discussions-main-container .discussions-header .discussions-board-chips .discussions-board-chip.inactive:hover {
  background-color: #FCC1D6;
}
.discussions-main-container .discussions-header .discussions-board-chips .discussions-board-chip.active {
  max-width: 450px;
  background-color: #bc1954;
}
.discussions-main-container .discussions-header .discussions-board-chips .discussions-board-chip .board-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}
.discussions-main-container .discussions-header .discussions-header-controls {
  display: flex;
}
@media screen and (max-width: 47.99em) {
  .discussions-main-container .discussions-header .discussions-header-controls {
    font-size: 0.8em;
  }
  .discussions-main-container .discussions-header .discussions-header-controls .button {
    padding: 8px 10px;
  }
}
.discussions-main-container .discussions-header .discussions-discussions-board-filters {
  margin-left: 10px;
  margin-right: 0;
}
@media screen and (max-width: 47.99em) {
  .discussions-main-container .discussions-header .discussions-discussions-board-filters {
    margin-left: 5px;
  }
}
.discussions-main-container .discussions-header .discussions-discussions-board-filters .discussions-board-filter-button .button {
  border-radius: 50%;
  padding: 12px 16px;
  font-size: 1em;
}
@media screen and (max-width: 47.99em) {
  .discussions-main-container .discussions-header .discussions-discussions-board-filters .discussions-board-filter-button .button {
    padding: 6px 12px;
  }
  .discussions-main-container .discussions-header .discussions-discussions-board-filters .discussions-board-filter-button .button i {
    font-size: 0.8em;
  }
}
.discussions-main-container .discussions-header .button {
  margin-bottom: 0;
  white-space: nowrap;
}
@media screen and (max-width: 47.99em) {
  .discussions-main-container .discussions-header .button {
    font-size: 0.8em;
  }
}
.discussions-main-container .discussions-embeds-container-overflow .discussions-embeds-container .embed-item {
  width: calc(50% - 40px);
}
@media screen and (max-width: 47.99em) {
  .discussions-main-container .discussions-embeds-container-overflow .discussions-embeds-container {
    text-align: left;
  }
}

.discussions-board-filter-dialog.dialog {
  max-width: 35.5em;
}
@media screen and (min-width: 48em) {
  .discussions-board-filter-dialog.dialog {
    margin: 4vw auto;
  }
}
.discussions-board-filter-dialog .discussions-board-sort {
  display: flex;
  margin-top: 5px;
  margin-bottom: 20px;
}
.discussions-board-filter-dialog .discussions-board-sort .discussions-board-sort-chip {
  display: inline;
  margin-right: 10px;
  padding: 5px 20px;
  font-weight: normal;
  background-color: #d9d9d9;
  color: black;
}
@media screen and (max-width: 47.99em) {
  .discussions-board-filter-dialog .discussions-board-sort .discussions-board-sort-chip {
    padding: 5px 15px;
    font-size: 0.9em;
  }
}
.discussions-board-filter-dialog .discussions-board-sort .discussions-board-sort-chip:hover:not(.active) {
  background-color: #FCC1D6;
}
.discussions-board-filter-dialog .discussions-board-sort .discussions-board-sort-chip.active {
  background-color: #bc1954;
  color: white;
}
.discussions-board-filter-dialog .discussions-board-filter-header .discussions-board-filter-header-reset {
  margin-left: auto;
  margin-right: 0;
  color: #bc1954;
}
.discussions-board-filter-dialog .discussions-board-filter {
  margin-top: 20px;
}
.discussions-board-filter-dialog .discussions-board-search {
  margin-bottom: 5px;
}
.discussions-board-filter-dialog .discussions-board-filter-form input[type=text], .discussions-board-filter-dialog .discussions-board-filter-form select {
  border-radius: 20px;
  margin-right: 0;
}
.discussions-board-filter-dialog .discussions-board-filter-form .advanced-select {
  width: 100%;
}
.discussions-board-filter-dialog .discussions-board-filter-form .advanced-select .advanced-select__control {
  border-radius: 20px;
}
.discussions-board-filter-dialog .discussions-board-filter-form .advanced-select .advanced-select__value-container {
  border-radius: 20px 0 0 20px;
}
.discussions-board-filter-dialog .discussions-board-filter-form .advanced-select .advanced-select__indicators {
  border-radius: 0 20px 20px 0;
}
.discussions-board-filter-dialog .discussions-discussions-board-filters-submit .button {
  border-radius: 20px;
  padding: 0.5em 0.6em;
}

.discussions-images-overlay {
  background: rgba(0, 0, 0, 0.55);
}
