/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/**
 * Margins and paddings
 */
/**
 * Mobile breakpoints:
 * These indicate the minimum width for each breakpoint.
 * If you intend to change the breakpoints, you should
 * change the minimum/maximum widths assigned to the
 * breakpoints in pure/css/grids-responsive.css too.
 * Approx 16px per em.
 */
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/**
 * Sizes
 */
/**
 * Colors
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  FLOATING ACTION BUTTONS
  Used for main menu
-------------------------------*/
/* ---------- FAB MAIN BUTTON ICON ---------- */
/* Use icon_menu_rocket_black.png if black icons are preferred */
/* ---------- FAB COLORS ---------- */
/* ---------- FAB SIZE ---------- */
/* ---------- FAB MARGINS / PADDINGS ---------- */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  LEFT SIDEBAR
-------------------------------*/
/* ---------- BACKGROUND ---------- */
/* ---------- TEXT COLORS ---------- */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Borders
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Typography
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/**
 * Sizes
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/**
 * Colors (Buttons)
 */
/*------------------------------------*\
    # PROJECT PAGE
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  FLOATING ACTION BUTTONS
  Used for main menu
-------------------------------*/
/* ---------- FAB MAIN BUTTON ICON ---------- */
/* Use icon_menu_rocket_black.png if black icons are preferred */
/* ---------- FAB COLORS ---------- */
/* ---------- FAB SIZE ---------- */
/* ---------- FAB MARGINS / PADDINGS ---------- */
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARD SLIDER
\*------------------------------------*/
/**
 * Background
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # PROJECT TITLE CARD
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARDS
\*------------------------------------*/
/**
 * Sizes (CTA Buttons)
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/**
 * Margins and paddings
 */
/**
 * Mobile breakpoints:
 * These indicate the minimum width for each breakpoint.
 * If you intend to change the breakpoints, you should
 * change the minimum/maximum widths assigned to the
 * breakpoints in pure/css/grids-responsive.css too.
 * Approx 16px per em.
 */
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/**
 * Sizes
 */
/**
 * Colors
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  FLOATING ACTION BUTTONS
  Used for main menu
-------------------------------*/
/* ---------- FAB MAIN BUTTON ICON ---------- */
/* Use icon_menu_rocket_black.png if black icons are preferred */
/* ---------- FAB COLORS ---------- */
/* ---------- FAB SIZE ---------- */
/* ---------- FAB MARGINS / PADDINGS ---------- */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  LEFT SIDEBAR
-------------------------------*/
/* ---------- BACKGROUND ---------- */
/* ---------- TEXT COLORS ---------- */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Borders
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Typography
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/**
 * Sizes
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/**
 * Colors (Buttons)
 */
/*------------------------------------*\
    # PROJECT PAGE
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  FLOATING ACTION BUTTONS
  Used for main menu
-------------------------------*/
/* ---------- FAB MAIN BUTTON ICON ---------- */
/* Use icon_menu_rocket_black.png if black icons are preferred */
/* ---------- FAB COLORS ---------- */
/* ---------- FAB SIZE ---------- */
/* ---------- FAB MARGINS / PADDINGS ---------- */
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARD SLIDER
\*------------------------------------*/
/**
 * Background
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # PROJECT TITLE CARD
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARDS
\*------------------------------------*/
/**
 * Sizes (CTA Buttons)
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # MARGIN & PADDING
\*------------------------------------*/
.automargin {
  margin: auto;
}

.nomargin {
  margin: 0 !important;
}

.topmargin {
  margin-top: 20px;
}

.topmargin-5 {
  margin-top: 5px;
}

.topmargin-10 {
  margin-top: 10px;
}

.topmargin-20 {
  margin-top: 20px;
}

.topmargin-30 {
  margin-top: 30px;
}

.topmargin-40 {
  margin-top: 40px;
}

.bottommargin {
  margin-bottom: 20px;
}

.bottommargin-10 {
  margin-bottom: 10px;
}

.bottommargin-20 {
  margin-bottom: 20px;
}

.bottommargin-30 {
  margin-bottom: 30px;
}

.bottommargin-40 {
  margin-bottom: 40px;
}

.leftmargin-20, .leftmargin {
  margin-left: 20px !important;
}

.leftmargin-5 {
  margin-left: 5px !important;
}

.leftmargin-10 {
  margin-left: 10px !important;
}

.leftmargin-15 {
  margin-left: 15px !important;
}

.leftmargin-30 {
  margin-left: 30px !important;
}

.rightmargin-20, .rightmargin {
  margin-right: 20px !important;
}

.rightmargin-5 {
  margin-right: 5px !important;
}

.rightmargin-10 {
  margin-right: 10px !important;
}

.rightmargin-15 {
  margin-right: 15px !important;
}

.rightmargin-30 {
  margin-right: 30px !important;
}

.verticalmargin-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.horizontalmargin-10 {
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.innerpadding {
  padding: 10px;
  box-sizing: border-box;
}

.padding {
  padding: 20px;
}

.horizontalpadding {
  padding-left: 20px;
  padding-right: 20px;
}

.horizontalpadding-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.horizontalpadding-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.verticalpadding {
  padding-top: 20px;
  padding-bottom: 20px;
}

.verticalpadding-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.verticalpadding-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.toppadding {
  padding-top: 20px;
}

.toppadding-10 {
  padding-top: 10px;
}

.toppadding-20 {
  padding-top: 20px;
}

.bottompadding {
  padding-bottom: 20px;
}

.bottompadding-5 {
  padding-bottom: 5px;
}

.bottompadding-10 {
  padding-bottom: 10px;
}

.bottompadding-20 {
  padding-bottom: 20px;
}

.bottompadding-floating {
  padding-bottom: 97px;
}
@media screen and (max-width: 575px) {
  .bottompadding-floating {
    padding-bottom: 107px;
  }
}

.bottompadding-floating-extended {
  padding-bottom: 129px;
}
@media screen and (max-width: 575px) {
  .bottompadding-floating-extended {
    padding-bottom: 159px;
  }
}

/*------------------------------------*\
    # BORDERS
\*------------------------------------*/
.border-white {
  border: 3px solid #fff;
}

.border-gray-lighter, .project-card-bg .square-image, .team-card-bg .square-image {
  border: 3px solid #f6f6f6;
}

.border-gray-light {
  border: 3px solid #eee;
}

.border-gray {
  border: 3px solid #666;
}

.border-gray-dark {
  border: 3px solid #555;
}

.border-gray-darker {
  border: 3px solid #292929;
}

.border-gray-darkest {
  border: 3px solid #111;
}

.border-green {
  border: 3px solid #85d02f;
}

.border-thin {
  border-width: 1px;
}

.border-thick {
  border-width: 5px;
}

/*------------------------------------*\
    # TEXT
\*------------------------------------*/
.project-card-bg .title, .team-card-bg .title, .darkbg {
  color: #f6f6f6 !important;
}

.bm-item span {
  /* Ellipsis */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*------------------------------------*\
    # CURSOR TYPES
\*------------------------------------*/
.cursor-pointer {
  cursor: pointer !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/* to be paired with react-dropdown */
/* https://github.com/trendmicro-frontend/react-dropdown */
/*------------------------------------*\
    # IFRAMES
\*------------------------------------*/
/* ---------- FONT-FACE DECLARATIONS ---------- */
@font-face {
  font-family: "Arial Rounded";
  src: url("../../fonts/core/arial_rounded_bold.woff2") format("woff2"), url("../../fonts/core/arial_rounded_bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
/* ---------- RESETS ---------- */
html, body {
  padding: 0;
  margin: 0;
  /* Allows content to fill the viewport and go beyond the bottom */
  height: 100%;
}

html.ios-body, body.ios-body {
  /* To smooth any scrolling behavior */
  -webkit-overflow-scrolling: touch;
}

h1, h2, h3, h4, h5, h6, p, form, button {
  margin: 0;
  padding: 0;
}

ul.reset-list, li.reset-list-item {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

img {
  border: 0;
}

input, textarea, select, option {
  outline: none;
  border: none;
}

.box-sizing-border-box {
  box-sizing: border-box;
}

.box-sizing-border-box-all, .box-sizing-border-box-all * {
  box-sizing: border-box;
}

/* ---------- LAYOUT ---------- */
/* Required for hack to render page-specific background */
#root {
  height: 100%;
}

[data-reactroot] {
  height: 100%;
}

.page-wrapper {
  height: 100%;
}

.page-container {
  height: 100%;
  display: flex;
  margin-bottom: 0;
}
.page-container .sidebar-wrapper {
  flex-grow: 1;
}
.page-container .page {
  flex-grow: 3;
}
.page-container .sidebar-wrapper, .page-container .page {
  flex-basis: 0;
}

/* Extra padding to accommodate fixed navbar */
.page {
  height: 100%;
}

/*
  .component-container's height and padding changes depending on whether topbar is mounted.
*/
.component-container {
  /*
    Set height which will be overriden by min-height anyway.
    This height is necessary so that child components can
    use height: 100%;
  */
  height: 1px;
  min-height: 100%;
}

.topbar-wrap ~ .page-container .component-container {
  padding-top: 50px;
  min-height: calc(100% - 50px);
}

.container {
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}
.container .innerblock {
  width: 100%;
  display: block;
  max-width: 64em;
  margin: 0 auto;
  box-sizing: border-box;
}

.relative {
  position: relative !important;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.fullwidth {
  width: 100%;
}

.zerobottom {
  bottom: 0 !important;
}

.align-right {
  margin-left: auto;
  margin-right: 0;
}

.description-content table {
  width: 100%;
  box-sizing: border-box;
  border-collapse: collapse;
  border-spacing: 0;
}
.description-content table th, .description-content table td {
  border: 1px solid #ccc;
  padding: 8px;
}
.description-content table th {
  background: #e4e4e4;
}

table.fixedlayout {
  table-layout: fixed;
  margin-bottom: 0px;
}

.main-background {
  background: #fff;
}

.darkbg {
  background-color: #111;
}

.highlightbg {
  background-color: #bc1954;
  color: #fff;
}
.highlightbg:hover {
  background-color: #a5164a;
}

.topbar {
  min-height: 50px;
}

.topbarbg {
  background: #111;
  color: #f6f6f6;
}

.sidebarbg {
  background: #f6f6f6;
  color: #292929;
}
.sidebarbg .smalltext {
  color: #666;
}

.cardlist .card {
  border: 1px solid #eee;
  border-radius: 20px;
  padding: 20px;
}

.display-block {
  display: block !important;
}

.display-inline-block {
  display: inline-block !important;
}

@media screen and (max-width: 35.49em) {
  .hide-below-sm {
    display: none !important;
  }
}
@media screen and (max-width: 39.99em) {
  .hide-below-md-640 {
    display: none !important;
  }
}
@media screen and (max-width: 47.99em) {
  .hide-below-md {
    display: none !important;
  }
}
@media screen and (max-width: 63.99em) {
  .hide-below-lg {
    display: none !important;
  }
}
@media screen and (max-width: 79.99em) {
  .hide-below-xl {
    display: none !important;
  }
}
@media screen and (min-width: 35.5em) {
  .hide-from-sm {
    display: none !important;
  }
}
@media screen and (min-width: 40em) {
  .hide-from-md-640 {
    display: none !important;
  }
}
@media screen and (min-width: 48em) {
  .hide-from-md {
    display: none !important;
  }
}
@media screen and (min-width: 64em) {
  .hide-from-lg {
    display: none !important;
  }
}
@media screen and (min-width: 80em) {
  .hide-from-xl {
    display: none !important;
  }
}
.hidden {
  display: none !important;
}

/* ---------- ALIGNMENT ---------- */
.inline {
  display: inline;
}

.table {
  display: table;
}

.tablecell {
  display: table-cell;
}

/* Flex settings */
.flex {
  display: flex;
}

@media screen and (max-width: 35.49em) {
  .flex-below-sm {
    display: flex;
  }
}
@media screen and (max-width: 47.99em) {
  .flex-below-md {
    display: flex;
  }
}
@media screen and (max-width: 63.99em) {
  .flex-below-lg {
    display: flex;
  }
}
@media screen and (max-width: 79.99em) {
  .flex-below-xl {
    display: flex;
  }
}
@media screen and (min-width: 35.5em) {
  .flex-from-sm {
    display: flex;
  }
}
@media screen and (min-width: 48em) {
  .flex-from-md {
    display: flex;
  }
}
@media screen and (min-width: 64em) {
  .flex-from-lg {
    display: flex;
  }
}
@media screen and (min-width: 80em) {
  .flex-from-xl {
    display: flex;
  }
}
.flex-shrink-none {
  flex-shrink: 0;
}

.flex-grow-none {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-wrap-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap-wrap {
  flex-wrap: wrap;
}

.flex-wrap-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.justify-content-flexend {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.align-items-flexstart {
  align-items: flex-start;
}

.align-items-center {
  align-items: center;
}

@media screen and (max-width: 35.5em) {
  .justify-content-sm-center {
    justify-content: center;
  }
}

.verticalcenter, .center { /* usage: parent must have position:relative */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.verticalcenter.textright, .textright.center {
  right: 0;
}

.center { /* usage: parent must have position:relative */ }

.imgcenter {
  display: block;
  margin: auto;
}

.textcenter, .center {
  text-align: center;
}

.textright {
  text-align: right;
}

.floatright {
  float: right;
}

.floatleft {
  float: left;
}

.alignmiddle {
  vertical-align: middle;
}

/* ---------- MARGIN & PADDING ---------- */
@media screen and (min-width: 64em) {
  .on-lg-horizontalpadding {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.nopadding {
  padding: 0px;
}

@media screen and (max-width: 48em) {
  .below-md-nopadding {
    padding: 0px;
  }
}

.inheritheight {
  height: inherit;
}

.fullheight {
  height: 100%;
}

/* Make a container full height, and aligns content in the middle */
.fullheight-content-middle {
  height: calc(100vh - 170px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.translucent {
  opacity: 0.5;
}

/* ---------- BORDERS ---------- */
/* ---------- CONTENT BOXES ---------- */
.content-box-border {
  border: 1px solid #eee;
  border-radius: 15px;
  padding: 15px;
}

.content-box-background {
  background-color: #eee;
  border-radius: 15px;
  padding: 15px;
}

.content-box-background-dark {
  background-color: #292929;
  color: #fff;
  border-radius: 15px;
  padding: 15px;
}

/* ---------- IMAGES ---------- */
.square-image-wrapper {
  display: block;
  position: relative;
}

.square-image {
  display: block;
  padding-bottom: 100%;
  overflow: hidden;
  position: relative;
  box-sizing: content-box;
  width: 100%;
}

.square-image img {
  position: absolute;
  object-fit: cover;
  height: auto;
  min-height: 100%;
  left: 0;
  top: 0;
  border: 0;
  width: 100%;
}

.lockoverlay { /* to be placed above (adjacent) .square-image html elements */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  background-image: url("../../../images/core/placeholders/item_locked_icon_large@2x.png");
  background-size: cover;
}

.imgresponsive {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.responsive-img-description img {
  height: auto;
  max-width: 100%;
}

.iconxs {
  width: 37px;
  height: 37px;
}

.iconxs-flex-basis {
  flex-basis: 37px;
}

.iconsmall {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: black;
  margin: 0 1.5px;
  object-fit: cover;
}

.iconmedium {
  width: 80px;
  height: 80px;
}

.iconlarge {
  width: 120px;
  height: 120px;
}

.circle {
  border-radius: 50%;
  object-fit: cover;
}

/* ---------- DECORATION ---------- */
.boxshadow {
  box-shadow: 1px 1px 3px #999;
}

.highlightbox {
  background: #bc1954;
  color: #fff;
}
.highlightbox .smalltext {
  color: #fff;
}

/* ---------- LINES ---------- */
hr.default {
  border-color: #999;
}

hr.dark {
  border-color: #666;
}

/* ---------- POSITION ---------- */
/* Sticky positioning once element scrolls off the screen, offsets top bar only */
.position-sticky-offset-topbar {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 65px;
  z-index: 19;
}

/* ---------- INPUTS ---------- */
textarea {
  resize: none;
  height: 100px;
}

/*
  Force disabled fields to use readonly styling
*/
.pure-form select.pseudo-readonly[disabled] {
  background-color: #eee;
  color: #777;
  border: 1px solid #ccc;
  opacity: 1;
}

/*
  Select field using react-select
  Attempt to emulate styling used by pure-css
*/
.pure-form select {
  background-color: #eee;
}
.pure-form .advanced-select .advanced-select__control {
  border-radius: 8px;
}
.pure-form .advanced-select .advanced-select__control.advanced-select__control--is-focused, .pure-form .advanced-select .advanced-select__control.advanced-select__control--menu-is-open {
  border-color: #129FEA;
  box-shadow: none;
}
.pure-form .advanced-select .advanced-select__value-container {
  border-radius: 8px 0 0 8px;
  border-color: #129FEA;
}
.pure-form .advanced-select .advanced-select__indicators {
  border-radius: 0 8px 8px 0;
  border-color: #129FEA;
}
.pure-form .advanced-select .advanced-select__value-container, .pure-form .advanced-select .advanced-select__indicators {
  background-color: #eee;
}
.pure-form .advanced-select .advanced-select__indicator {
  color: #292929;
}
.pure-form .advanced-select .advanced-select__option.advanced-select__option--is-selected {
  background-color: #bc1954;
}
.pure-form .advanced-select .advanced-select__option.advanced-select__option--is-focused:not(.advanced-select__option--is-selected) {
  background-color: #ccc;
}

.fullwidth-filter {
  margin: auto;
  width: calc(100% - 40px);
  max-width: 500px;
}
.fullwidth-filter .advanced-select {
  width: 100%;
}

/*
  Disabled fields do not allow onClick handlers to be attached,
  so for most inputs readOnly suffices. However, for fields like <select>,
  where there is no readily available readOnly flag, I rely on
  a hack where I disable the field, as well as add an overlay (div) on
  top of the field to trigger any onClick handlers.
*/
.disable-check-input-container {
  position: relative;
  z-index: 1;
}
.disable-check-input-container .input-disabled-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

/* ---------- INPUTS: UPLOADERS ---------- */
.image-input-label {
  color: #666;
  border: 2px dashed #ccc;
}
.image-input-label .fas {
  color: #bc1954;
}
.image-input-label:hover {
  /* Disabled or inactive elements should not have hover effects */
}
.image-input-label:hover:not(.disabled) {
  color: #4d4d4d;
  border: 2px dashed #b3b3b3;
}
.image-input-label:hover:not(.disabled) .fas {
  color: #981444;
}
.image-input-label.disabled .fas {
  color: #ccc;
}

/* Old image uploader */
/* ---------- TEXT ANCHORS ---------- */
a.link {
  color: inherit;
}

/* By default, all links will not have any decoration */
a.link:hover {
  text-decoration: none;
}

/* Add underline only on hover */
a.link.underline-on-hover {
  text-decoration: none;
}

a.link.underline-on-hover:hover {
  text-decoration: underline;
}

/* Use brand link colour */
a.link.link-color {
  color: #bc1954;
}
a.link.link-color:hover {
  color: #8f1340;
}

/* ---------- CURSOR TYPES ---------- */
/* ---------- SLIDER ---------- */
/*
  <div> adjacent selectors applicable to react-slick v0.27.11,
  can remove once there's a breaking update that removes the additional divs
*/
.slick-slider:focus, .slick-list .slick-track:focus, .slick-slide:focus, .slick-active:focus, .slick-current:focus, .slick-slide > div:focus, .slick-slide > div > div:focus {
  outline: none;
}

/* ---------- PROGRESS BAR ---------- */
.progressbarbg {
  background-color: #DCECC8;
}
.progressbarbg .progress-block {
  background-color: #7CB341;
}

/* ---------- FLOATING MENU BUTTON ---------- */
/* Background of child and main buttons */
.mfb-component__button--main, .mfb-component__button--child {
  background-color: #bc1954;
}

/* Bars and lines of cross and bars icon of main button */
.floating-menu-icon-color span {
  color: #fff;
}

/* Font Awesome icons of child buttons */
a.link.mfb-component__button--child {
  color: #fff;
}

/* ---------- TOPBAR MENU ---------- */
/* Position and sizing of burger button */
.bm-burger-button {
  display: none;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #fff;
}

.bm-cross-button button:focus {
  outline: 0;
}

/* Topbar menu overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.75);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/*
  Topbar menu wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  top: 0;
  height: 100%;
}

.bm-menu {
  background: #292929;
  padding: 2.5em 0 0;
  font-size: 1.15em;
}

.bm-item-list {
  height: calc(100dvh - 50px - 1.6em) !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for menu item list */
.bm-item-list {
  color: #fff;
  padding: 0.8em 0;
}
.bm-item-list:visited, .bm-item-list:focus {
  color: #fff;
}

/* Individual menu item */
.bm-item {
  padding: 0.8em 2.3em;
}
.bm-item.link {
  color: #fff;
}
.bm-item:not(.menu-app-version):hover {
  background: #bc1954;
  color: #fff;
}
.bm-item.menu-app-version {
  font-size: 0.8em;
  color: #666;
  /* Align with menu links */
  margin-left: 6px;
}
.bm-item i {
  min-width: 20px;
  margin-right: 10px;
}
.bm-item i, .bm-item span {
  display: block;
}
.bm-item.logout-link {
  display: flex;
  align-items: center;
}
.bm-item.menu-divider {
  padding: 0;
  margin: 0.8em 1.5em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.bm-item.menu-divider:hover {
  background: transparent;
}

/* ---------- PROFILE DRAWER ---------- */
.drawer-profile-wrapper-bg {
  background: rgba(0, 0, 0, 0.85);
}

.drawer-profile-img-border {
  border: 2px solid #fff;
}

/* ---------- LOGIN & REGISTER PAGES ---------- */
.login-register-container .button.cta, .login-register-container .button.cta a {
  color: #fff;
  background: #bc1954;
}

/* ---------- PROJECTS LIST PAGE ---------- */
.project-card-container .search-bar .button {
  position: static;
}

.project-search .project-list {
  padding-top: 0;
}

/* ---------- PROJECTS LIST PAGE: PROJECT CARDS ---------- */
.project-card-bg, .team-card-bg {
  background: black;
}
.project-card-bg p, .team-card-bg p {
  color: #b6b6b6;
  font-size: 0.875em;
}
.project-card-bg .title, .team-card-bg .title {
  font-weight: bold;
}
/* ---------- PROJECT PAGE: TOPIC CARDS ---------- */
.card-action {
  background-color: #bc1954;
}

.card-locked {
  background-color: #bbb;
}

/* ---------- PROJECT PAGE: CATEGORY SECTION ---------- */
.category-img {
  border: 3px solid #ddd;
}

/* ---------- MOBILE PROJECT PAGE: TOPBAR ---------- */
.mobile-project-topbar-wrap .topbarbg {
  background: #111;
  color: #f6f6f6;
}

/* ---------- MOBILE PROJECT PAGE: SIDEBAR ---------- */
.sidebar-links a {
  background: #f6f6f6;
  color: #000;
  /*
    margin-left depends on the value of the border width, if
    sidebar link buttons require borders for custom projects:
    with a border-width of 3px, margin-left should be -3px
  */
  margin-left: 0;
}

/* ---------- MOBILE PROJECT PAGE: PROJECT TITLE CARD ---------- */
.project-title-card-bg, .featured-project-card-bg {
  background: rgba(17, 17, 17, 0.8);
  color: #f6f6f6;
  border: 1px solid #fff;
}
@media screen and (min-width: 48em) {
  .project-title-card-bg, .featured-project-card-bg {
    background: #111;
    color: #e9e9e9;
    border: 0;
  }
}

/* ---------- TEAMS PAGE: TEAM CARD ---------- */
.team-card-bg .title {
  font-weight: normal;
}

/* ---------- USER ICON WITH RANK ---------- */
.photo-icon-wrap {
  position: relative;
  width: 100%;
}
.photo-icon-wrap.xs-icon-wrap {
  width: 37px;
}
.photo-icon-wrap .rank-status {
  width: 50%;
  height: auto;
  background-size: contain;
  position: absolute;
  bottom: -5%;
  right: -5%;
  z-index: 2;
}

/* ---------- LINK OVERLAY ---------- */
/*
  This makes an entire card component clickable. Usage is as follows:

  <div className="card-link-wrapper"> // Outer card DIV
    <a href="#" className="card-link-overlay"></a>

    // The rest of your card content goes here.
    // The overlay link defined above ensures your entire card remains clickable.
    // Whereas other link/form/button conponents still remain interactable.

  <div>
*/
.card-link-wrapper {
  position: relative;
  z-index: 1;
}
.card-link-wrapper .card-link-overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2; /* more than .card-link-wrapper (1) */
}
.card-link-wrapper a, .card-link-wrapper button, .card-link-wrapper input {
  position: relative;
  z-index: 3; /* more than .card-link-overlay (2) */
}
