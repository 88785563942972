/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/**
 * Margins and paddings
 */
/**
 * Mobile breakpoints:
 * These indicate the minimum width for each breakpoint.
 * If you intend to change the breakpoints, you should
 * change the minimum/maximum widths assigned to the
 * breakpoints in pure/css/grids-responsive.css too.
 * Approx 16px per em.
 */
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/**
 * Sizes
 */
/**
 * Colors
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  FLOATING ACTION BUTTONS
  Used for main menu
-------------------------------*/
/* ---------- FAB MAIN BUTTON ICON ---------- */
/* Use icon_menu_rocket_black.png if black icons are preferred */
/* ---------- FAB COLORS ---------- */
/* ---------- FAB SIZE ---------- */
/* ---------- FAB MARGINS / PADDINGS ---------- */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  LEFT SIDEBAR
-------------------------------*/
/* ---------- BACKGROUND ---------- */
/* ---------- TEXT COLORS ---------- */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Borders
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Typography
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/**
 * Sizes
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/**
 * Colors (Buttons)
 */
/*------------------------------------*\
    # PROJECT PAGE
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  FLOATING ACTION BUTTONS
  Used for main menu
-------------------------------*/
/* ---------- FAB MAIN BUTTON ICON ---------- */
/* Use icon_menu_rocket_black.png if black icons are preferred */
/* ---------- FAB COLORS ---------- */
/* ---------- FAB SIZE ---------- */
/* ---------- FAB MARGINS / PADDINGS ---------- */
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARD SLIDER
\*------------------------------------*/
/**
 * Background
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # PROJECT TITLE CARD
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARDS
\*------------------------------------*/
/**
 * Sizes (CTA Buttons)
 */
/**
 * Margins and paddings
 */
.search-challenge-dialog .button {
  margin-bottom: 0;
}
.search-challenge-dialog .button:not(:first-child) {
  margin-left: 10px;
}
