/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/**
 * Margins and paddings
 */
/**
 * Mobile breakpoints:
 * These indicate the minimum width for each breakpoint.
 * If you intend to change the breakpoints, you should
 * change the minimum/maximum widths assigned to the
 * breakpoints in pure/css/grids-responsive.css too.
 * Approx 16px per em.
 */
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/**
 * Sizes
 */
/**
 * Colors
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  FLOATING ACTION BUTTONS
  Used for main menu
-------------------------------*/
/* ---------- FAB MAIN BUTTON ICON ---------- */
/* Use icon_menu_rocket_black.png if black icons are preferred */
/* ---------- FAB COLORS ---------- */
/* ---------- FAB SIZE ---------- */
/* ---------- FAB MARGINS / PADDINGS ---------- */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  LEFT SIDEBAR
-------------------------------*/
/* ---------- BACKGROUND ---------- */
/* ---------- TEXT COLORS ---------- */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Borders
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Typography
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/**
 * Sizes
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/**
 * Colors (Buttons)
 */
/*------------------------------------*\
    # PROJECT PAGE
\*------------------------------------*/
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/* ------------------------------
  FLOATING ACTION BUTTONS
  Used for main menu
-------------------------------*/
/* ---------- FAB MAIN BUTTON ICON ---------- */
/* Use icon_menu_rocket_black.png if black icons are preferred */
/* ---------- FAB COLORS ---------- */
/* ---------- FAB SIZE ---------- */
/* ---------- FAB MARGINS / PADDINGS ---------- */
/* ------------------------------
  COLOR DEFINITIONS
-------------------------------*/
/* ---------- COLOR DEFINITIONS ---------- */
/* ---------- GREYSCALE ---------- */
/* ---------- INTERFACE ELEMENTS ---------- */
/* Backgrounds */
/* Text */
/* Warnings */
/* Call to Action */
/* Segment Dividers */
/* Highlighted / Hover / Selected State */
/* Links */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARD SLIDER
\*------------------------------------*/
/**
 * Background
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # PROJECT TITLE CARD
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARDS
\*------------------------------------*/
/**
 * Sizes (CTA Buttons)
 */
/**
 * Margins and paddings
 */
.reward-store .reward-store-points {
  margin-bottom: 15px;
}
.reward-store .rewards-filter-form {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.reward-store .rewards-filter-form.pure-form p {
  margin-bottom: 0;
}
.reward-store .rewards-category-select-container {
  text-align: center;
}
@media screen and (max-width: 47.99em) {
  .reward-store .rewards-category-select-container {
    margin: auto;
  }
}
@media screen and (max-width: 47.99em) {
  .reward-store .rewards-search-container {
    margin-top: 10px;
  }
  .reward-store .rewards-search-container .search-bar input.mobile-radius {
    border-radius: 8px;
  }
}
.reward-store .reward-card {
  border: 1px solid #eeeeee;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px;
}
@media screen and (max-width: 47.99em) {
  .reward-store .reward-card {
    padding: 10px;
  }
}
.reward-store .reward-redemption-cta {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.reward-store .reward-redemption-cta .reward-collect {
  margin-right: 20px;
}
@media screen and (max-width: 47.99em) {
  .reward-store .reward-redemption-cta {
    display: block;
  }
  .reward-store .reward-redemption-cta .reward-collect {
    margin-bottom: 10px;
  }
}

.reward-info-container {
  text-align: left;
}

.reward-redeem-info {
  margin-bottom: 15px;
}
